import React from 'react';
import HeroSlider from '../components/homePage/PromotionSlider';
import ProductHighlights from '../components/homePage/ProductHighlights';
import Testimonials from '../components/homePage/Testimonials';
import NewsletterSignup from '../components/homePage/NewsletterSignup';
import ContentHubPreview from '../components/homePage/ContentHubPreview';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Homepage.scss';

const Homepage = () => {
  return (
    <div className="homepage">
      <section className="hero-slider">
        <HeroSlider />
      </section>
      <section className="product-highlights section">
        <div className="container">
          <h2 className="section-title">Featured Products</h2>
          <ProductHighlights />
        </div>
      </section>
      <section className="content-hub-preview section bg-light">
        <div className="container">
          <h2 className="section-title">Latest Articles and Guides</h2>
          <ContentHubPreview />
        </div>
      </section>
      <section className="testimonials section">
        <div className="container">
          <h2 className="section-title">What Our Customers Say</h2>
          <Testimonials />
        </div>
      </section>
      <section className="newsletter-signup section bg-light">
        <div className="container">
          <h2 className="section-title">Stay Updated</h2>
          <NewsletterSignup />
        </div>
      </section>
      <section className="customer-support-preview section bg-light">
        <div className="container">
          <h2 className="section-title">Need Help?</h2>
          <p>Visit our customer support section for FAQs and live chat support.</p>
          <a href="/customer-support" className="btn">Go to Customer Support</a>
        </div>
      </section>
    </div>
  );
};

export default Homepage;