import React from 'react';
import './RelatedProducts.scss';

const RelatedProducts = ({ products }) => {
  return (
    <div className="related-products">
      <h2>Related Products</h2>
      <div className="related-products-carousel">
        {products.map((product) => (
          <div key={product.id} className="related-product-item">
            <img src={product.thumbnail_image} alt={product.name} />
            <h3>{product.name}</h3>
            <p>£{product.price}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RelatedProducts;