import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fetchContentDetail, fetchComments, fetchRatings, postComment, postRating, likeContent, likeComment } from '../api/contentApi';
import sanitizeHtml from 'sanitize-html';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faStar } from '@fortawesome/free-solid-svg-icons';
import './ContentDetail.scss';

// Helper function to convert a YouTube URL to an embeddable format
const convertToEmbedURL = (url) => {
  const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);
  return match ? `https://www.youtube.com/embed/${match[1]}` : url;
};

const ContentDetail = () => {
  const { id } = useParams();
  const userId = useSelector((state) => state.user.userInfo?.id);

  const [content, setContent] = useState(null);
  const [comments, setComments] = useState([]);
  const [ratings, setRatings] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [newRating, setNewRating] = useState(0);
  const [loading, setLoading] = useState(true);
  const [hasLikedContent, setHasLikedContent] = useState(false);
  const [userRating, setUserRating] = useState(null);

  useEffect(() => {
    const getContentDetail = async () => {
      try {
        const { data: contentData } = await fetchContentDetail(id);
        setContent(contentData);
        setHasLikedContent(contentData.has_liked);
        setUserRating(contentData.user_rating);

        const { data: commentData } = await fetchComments(id);
        const { data: ratingData } = await fetchRatings(id);

        setComments(commentData);
        setRatings(ratingData);
      } catch (error) {
        console.error('Error fetching content detail:', error);
      } finally {
        setLoading(false);
      }
    };
    getContentDetail();
  }, [id]);

  const handleContentLike = async () => {
    try {
      if (!hasLikedContent) {
        console.log('Liking content:', { id, userId });
        const { data } = await likeContent(id, userId);
        setContent({ ...content, likes: data.likes });
        setHasLikedContent(true);
      }
    } catch (error) {
      console.error('Error liking content:', error);
    }
  };

  const handleCommentLike = async (commentId) => {
    try {
      console.log('Liking comment:', { commentId });
      const { data } = await likeComment(commentId);
      setComments(comments.map(comment => 
        comment.id === commentId ? { ...comment, likes: data.likes, has_liked: true } : comment
      ));
    } catch (error) {
      console.error('Error liking comment:', error);
    }
  };

  const handleCommentSubmit = async () => {
    if (!newComment.trim()) return;
    const commentPayload = { text: newComment, content: id, user: userId };
    console.log('Submitting comment:', commentPayload);
    try {
      const { data: newCommentData } = await postComment(id, commentPayload);
      setComments([...comments, newCommentData]);
      setNewComment('');
    } catch (error) {
      console.error('Error posting comment:', error.response ? error.response.data : error.message);
    }
  };

  const handleRatingSubmit = async () => {
    if (newRating < 1 || newRating > 5) return;
    const ratingPayload = { score: newRating, content: id, user: userId };
    console.log('Submitting rating:', ratingPayload);
    try {
      if (!userRating) {
        const { data: newRatingData } = await postRating(id, ratingPayload);
        setRatings([...ratings, newRatingData]);
        setUserRating(newRating);
        setNewRating(0);
      }
    } catch (error) {
      console.error('Error posting rating:', error.response ? error.response.data : error.message);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (!content) return <div>Error loading content. Please try again later.</div>;

  const sanitizedContent = sanitizeHtml(content.body, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat([
      'img', 'h1', 'h2', 'h3', 'p', 'ul', 'ol', 'li', 'strong', 'em', 'a', 'table', 'tr', 'th', 'td', 'thead', 'tbody', 'iframe']),
    allowedAttributes: {
      ...sanitizeHtml.defaults.allowedAttributes,
      img: ['src', 'alt', 'width', 'height', 'style'],
      a: ['href', 'target'],
      iframe: ['src', 'width', 'height', 'frameborder', 'allow', 'allowfullscreen'],
      '*': ['style', 'class', 'id']
    },
  });

  const contentType = content.content_type;
  const videoUrl = contentType === 'video' ? convertToEmbedURL(content.video_url) : null;

  return (
    <Container className={`content-detail ${contentType}-detail`}>
      <h1 className="title">{content.title}</h1>

      {/* Like button for content */}
      <Button variant="outline-primary" onClick={handleContentLike} disabled={hasLikedContent}>
        Like <FontAwesomeIcon icon={faThumbsUp} /> {content.likes}
      </Button>

      {contentType === 'video' && videoUrl && (
        <div className="video-container">
          <iframe
            width="100%"
            height="400px"
            src={videoUrl}
            title={content.title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      )}

      {['ebook', 'white_paper'].includes(contentType) && (
        <a href={content.file_attachment} download className="btn btn-secondary mb-3">
          Download {contentType.replace('_', ' ')}
        </a>
      )}

      <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} className="body-content" />

      <Row className="mt-4">
        <Col md={6}>
          <Card className="comments-section">
            <Card.Body>
              <Card.Title>Comments</Card.Title>
              {comments.length > 0 ? (
                comments.map(comment => (
                  <div key={comment.id} className="comment">
                    <p><strong>{comment.username}</strong>: {comment.text}</p>
                    <Button variant="link" onClick={() => handleCommentLike(comment.id)} disabled={comment.has_liked}>
                      Like <FontAwesomeIcon icon={faThumbsUp} /> {comment.likes}
                    </Button>
                  </div>
                ))
              ) : (
                <p>No comments available</p>
              )}
              <Form.Control
                as="textarea"
                rows={2}
                placeholder="Add a comment..."
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
              />
              <Button variant="primary" onClick={handleCommentSubmit} className="mt-2">
                Submit Comment
              </Button>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card className="ratings-section">
            <Card.Body>
              <Card.Title>Ratings</Card.Title>
              {ratings.length > 0 ? (
                ratings.map(rating => (
                  <Card.Text key={rating.id}>
                    Score: {rating.score} <FontAwesomeIcon icon={faStar} className="rating-star" />
                  </Card.Text>
                ))
              ) : (
                <p>No ratings available</p>
              )}
              <Form.Control
                type="number"
                min="1"
                max="5"
                placeholder="Rate this content (1-5)"
                value={newRating}
                onChange={(e) => setNewRating(Number(e.target.value))}
              />
              <Button variant="primary" onClick={handleRatingSubmit} disabled={!!userRating} className="mt-2">
                Submit Rating
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ContentDetail;
