// src/pages/LoginComponent.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, clearError } from '../features/user/userSlice';
import { useNavigate, Link } from 'react-router-dom';
import { syncCartAndFavourites } from '../api/sync';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faFacebook } from '@fortawesome/free-brands-svg-icons';
import './LoginComponent.scss';

const LoginComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status, userInfo, error, token } = useSelector((state) => state.user);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (userInfo && token) {
      setSuccessMessage('Login successful! Redirecting to checkout...');
      setTimeout(() => {
        syncCartAndFavourites(userInfo.id, dispatch);
        navigate('/checkout');
      }, 1000);
    }
  }, [userInfo, token, navigate, dispatch]);

  useEffect(() => {
    if (status === 'succeeded') {
      console.log('Login succeeded');
    }
  }, [status]);

  useEffect(() => {
    if (error) {
      console.log('Login error:', error);
      setErrorMessage('Invalid email or password.');
    } else {
      setErrorMessage('');
    }
  }, [error]);

  const handleLogin = (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');
    dispatch(clearError());
    dispatch(loginUser({ email, password }));
  };

  return (
    <div className="login-page container">
      <div className="login-card">
        <h2 className="text-center">Login</h2>
        {successMessage && <div className="alert alert-success">{successMessage}</div>}
        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label htmlFor="email">Email address</label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className="form-control"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary btn-block" disabled={status === 'loading'}>
            {status === 'loading' ? 'Logging in...' : 'Login'}
          </button>
        </form>
        <div className="social-login">
          <button type="button" className="btn btn-google">
            <FontAwesomeIcon icon={faGoogle} /> Login with Google
          </button>
          <button type="button" className="btn btn-facebook">
            <FontAwesomeIcon icon={faFacebook} /> Login with Facebook
          </button>
        </div>
        <div className="links text-center">
          <Link to="/forgot-password">Forgot Password?</Link>
          <p>Don't have an account? <Link to="/register">Register here</Link></p>
        </div>
      </div>
    </div>
  );
};

export default LoginComponent;