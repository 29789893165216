import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { removeFromFavourites } from '../features/favourites/favouritesSlice';
import { addToCart } from '../features/cart/cartSlice';
import './Favourites.scss';

const Favourites = () => {
  const favouriteItems = useSelector((state) => state.favourites.items);
  const cartItems = useSelector((state) => state.cart.items);
  const dispatch = useDispatch();

  const handleRemoveFromFavourites = (product) => {
    dispatch(removeFromFavourites(product));
  };

  const handleAddToCart = (product) => {
    if (!cartItems.some(item => item.id === product.id)) {
      dispatch(addToCart(product));
    }
  };

  return (
    <div className="favourites-page">
      <h1>Your Favourites</h1>
      {favouriteItems.length === 0 ? (
        <p>You have no favourite items</p>
      ) : (
        <div className="favourite-items">
          {favouriteItems.map((product) => (
            <div key={product.id} className="favourite-item">
              <Link to={`/product/${product.id}`}>
                <img src={product.thumbnail_image} alt={product.name} />
              </Link>
              <div className="favourite-item-info">
                <Link to={`/product/${product.id}`}>
                  <h2>{product.name}</h2>
                </Link>
                <p>Price: £{product.price}</p>
                <button onClick={() => handleRemoveFromFavourites(product)}>Remove</button>
                {!cartItems.some(item => item.id === product.id) && (
                  <button onClick={() => handleAddToCart(product)}>Add to Cart</button>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Favourites;
