// src/components/homePage/Testimonials.js
import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { axiosInstance } from '../../api/axiosConfig';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Testimonials.scss';

const Testimonials = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axiosInstance.get('/products/reviews/');
        setReviews(response.data);
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };
    fetchReviews();
  }, []);

  return (
    <div className="testimonials-container">
      {reviews.map((review, index) => (
        <Card key={`${review.id}-${index}`} className="testimonial-card">
          <Card.Body className="card-body">
            <Card.Title className="card-title">{review.product.name}</Card.Title>
            <Card.Text className="card-text">{review.comment}</Card.Text>
            <Card.Footer className="card-footer">
              <small className="text-muted">Rating: {review.rating}</small>
            </Card.Footer>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};

export default Testimonials;