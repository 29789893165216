import React from 'react';
import { useSelector } from 'react-redux';
import { Tabs, Tab, Container, Alert } from 'react-bootstrap';
import NewFAQ from '../components/CustomerSupport/NewFAQ';
import LiveChat from '../components/CustomerSupport/LiveChat';
import ContactForm from '../components/CustomerSupport/ContactForm';
import SupportTickets from '../components/CustomerSupport/SupportTickets';
import SearchComponent from '../components/CustomerSupport/SearchComponent';
import { motion } from 'framer-motion';
import './CustomerSupport.scss';

const tabVariants = {
  hidden: { opacity: 0, x: -50 },
  visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
};

const CustomerSupport = () => {
  const token = useSelector((state) => state.user.token);
  const isAuthenticated = !!token;

  return (
    <Container className="customer-support">
      <h1 className="text-center mb-4">Customer Support</h1>
      <motion.div initial="hidden" animate="visible" variants={tabVariants}>
        <Tabs defaultActiveKey="faqs" id="support-tabs" className="mb-3 tabs">
          <Tab eventKey="faqs" title="FAQs">
            <NewFAQ />
          </Tab>
          <Tab eventKey="tickets" title="Support Tickets">
            {isAuthenticated ? (
              <>
                <SearchComponent />
                <SupportTickets isAuthenticated={isAuthenticated} />
              </>
            ) : (
              <Alert variant="warning" className="text-center">
                Please <a href="/login">log in</a> to view and manage your support tickets.
              </Alert>
            )}
          </Tab>
          <Tab eventKey="chat" title="Live Chat">
            <LiveChat />
          </Tab>
          <Tab eventKey="contact" title="Contact Us">
            <ContactForm />
          </Tab>
        </Tabs>
      </motion.div>
    </Container>
  );
};

export default CustomerSupport;