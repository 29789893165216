import React, { useEffect, useState } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import UserProfile from '../components/userAccountDashboard/UserProfile';
import OrderHistory from '../components/userAccountDashboard/OrderHistory';
import UserSettings from '../components/userAccountDashboard/UserSettings';
import SubmitReviewModal from '../components/userAccountDashboard/SubmitReviewModal';
import ReturnForm from '../components/userAccountDashboard/ReturnForm';
import { fetchUser } from '../features/user/userSlice';
import { fetchOrders } from '../features/user/orderSlice';
import './UserDashboard.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Accordion, Card, Button } from 'react-bootstrap';

const UserDashboard = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.user);
  const { orders = [] } = useSelector((state) => state.order);
  const [reviewedOrders, setReviewedOrders] = useState([]);

  useEffect(() => {
    if (!userInfo) {
      dispatch(fetchUser());
    }
    if (userInfo) {
      dispatch(fetchOrders(userInfo.id));
    }
  }, [userInfo, dispatch]);

  const handleReviewSubmitted = (productId) => {
    setReviewedOrders([...reviewedOrders, productId]);
  };

  return (
    <div className="user-dashboard container-fluid">
      <header className="dashboard-header py-4 mb-4">
        <h1>Welcome, {userInfo?.username || 'User'}</h1>
      </header>
      <div className="row">
        <nav className="col-md-3 col-lg-2 sidebar mb-4">
          <ul className="nav flex-column nav-pills">
            <li className="nav-item"><Link to="profile" className="nav-link">Profile</Link></li>
            <li className="nav-item"><Link to="orders" className="nav-link">Order History</Link></li>
            <li className="nav-item"><Link to="settings" className="nav-link">Settings</Link></li>
            <li className="nav-item"><Link to="/memberships" className="nav-link">Membership</Link></li>
          </ul>
        </nav>
        <main className="col-md-9 col-lg-10">
          <div className="content">
            <Routes>
              <Route path="profile" element={<UserProfile />} />
              <Route path="orders" element={<OrderHistory />} />
              <Route path="settings" element={<UserSettings />} />
              <Route path="return/:orderId" element={<ReturnForm />} />
            </Routes>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>My Purchases</Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    {orders.map(order => (
                      order.items.map(item => (
                        !reviewedOrders.includes(item.product.id) && !item.reviewed ? (
                          <div key={item.id} className="col-md-6 col-lg-4">
                            <div className="product-card card mb-3">
                              <div className="card-body">
                                <h5 className="card-title">{item.product.name}</h5>
                                <SubmitReviewModal
                                  productId={item.product.id}
                                  onReviewSubmitted={() => handleReviewSubmitted(item.product.id)}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null
                      ))
                    ))}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Reviewed Purchases</Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    {orders.map(order => (
                      order.items.map(item => (
                        (item.reviewed || reviewedOrders.includes(item.product.id)) ? (
                          <div key={item.id} className="col-md-6 col-lg-4">
                            <div className="product-card card mb-3 reviewed">
                              <div className="card-body">
                                <h5 className="card-title">{item.product.name} - Review Submitted</h5>
                              </div>
                            </div>
                          </div>
                        ) : null
                      ))
                    ))}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </main>
      </div>
    </div>
  );
};

export default UserDashboard;